$(document).ready(function () {

  var calServiceColDimension = function () {
    if ($(window).width() < 992) {
      if ($('.services-container').length) {
        $('.services-container').removeAttr('style');
        $('.col-expandable').removeAttr('style');
      }
      return;
    }
    if ($('.services-container').length) {
      var maxColHeight = 0;
      $('.service-block').each(function () {
        var thisHeight = $(this).height();
        maxColHeight = thisHeight > maxColHeight ? thisHeight : maxColHeight;
      });
      $('.col-expandable').each(function () {
        $(this).css('width', $(this).width());
        $(this).find('.service-col').css('min-width', $(this).width());
        $(this).attr('data-org-width', $(this).width());
      });
      $('.services-container').css('min-height', maxColHeight);
    }
  };
  calServiceColDimension();

  if ($('.section-our-services').length) {
    $('.service-header').each(function () {
      $(this).on('click', function (e) {
        e.preventDefault();
        var thisCol = $(this).closest('.product-container');
        $('.product-container').not($(thisCol)).removeClass('active');
        $('.product-container').not($(thisCol)).css('z-index', '1');
        $(thisCol).toggleClass('active');
        $(thisCol).css('z-index', '2');
      });
    });
  }

  if ($('.tab-switcher').length) {
    $('.vt-tabs .nav-link').each(function () {
      $(this).on('click', function (e) {
        if ($('.tab-switcher').is(':visible')) {
          $('.tab-switcher').trigger('click');
          $('.tab-switcher span').html($(this).html());
        }
      });
    });
  }

  var debounce = null;
  $(window).on('resize', function (e) {
    clearTimeout(debounce);
    debounce = setTimeout(function () {
      calServiceColDimension();
    }, 200);
  });

  $('.megamenu').stellarNav({
    mobileMode: true,
    menuLabel: 'Đóng'
  });

  $('.menu-overlay').bind('touchmove', function (e) {
    e.preventDefault()
  });

  //	SLICK SLIDER
  $('.grid-list').slick({
    dots: false,
    infinite: false,
    speed: 300,
    arrows: true,
    prevArrow:"<button type='button' class='slick-prev pull-left'><i class='vt-icon icon-arrow_prev'></i></button>",
    nextArrow:"<button type='button' class='slick-next pull-right'><i class='vt-icon icon-arrow_next'></i></button>",
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });
});
